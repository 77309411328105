<template>
    <b-row class="justify-content-center">
        <b-col md="6">
            <b-card>
                <b-form v-on:submit.prevent>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group
                            label="Code"
                            label-for="code"
                            >
                            <b-form-input
                                id="code"
                                placeholder="Code"
                                v-model="form.code"
                                required
                            />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                            label="Name"
                            label-for="name"
                            >
                            <b-form-input
                                id="name"
                                placeholder="Name"
                                v-model="form.name"
                                required
                            />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                            label="Amount"
                            label-for="amount"
                            >
                            <cleave
                                id="amount"
                                placeholder="Amount"
                                v-model="form.amount"
                                class="form-control"
                                :raw="false"
                                :options="options.number"
                                required
                            />
                            </b-form-group>
                        </b-col>

                        <!-- submit and reset -->
                        <b-col md="12" class="mt-2">
                            <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="primary"
                            class="mr-1"
                            @click="updateEmployeePtkp()"
                            >
                            Update
                            </b-button>
                            <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="danger"
                            class="mr-1"
                            @click="deleteEmployeePtkp()"
                            >
                            Delete
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BListGroupItem, BAvatar, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'

export default {
    components: {
        ToastificationContent,
        Cleave,
        BListGroupItem,
        BAvatar,
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        draggable,
        Prism
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            form: {
                code: '',
                name: '',
                amount: null
            },
            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                },
            },
            errors: '',
            errMessage: ''
        }
    },
    created() {
        const id = this.$route.params.id
        // console.log(id);

        this.$http
            .get('employee/ptkp/' +id)
            .then((response) => {
                this.form.code = response.data.data.code
                this.form.name = response.data.data.name
                this.form.amount = response.data.data.amount
                // console.log(response.data.data)
            }).catch((err) => {
                console.log(err.response)
            });
    },
    methods: {
        updateEmployeePtkp() {
            const id = this.$route.params.id

            var newAmount = this.form.amount
            newAmount = newAmount.replace(/\,/g,'')
            this.$http
            .post("employee/ptkp/"+id+"/update", {
                "code": this.form.code,
                "name": this.form.name,
                "amount": newAmount
            })
            .then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Success update Employee PTKP',
                        variant: 'success',
                    },
                })
                location.href = "/settings/employee-ptkp"
                // console.log(response.data.data)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        deleteEmployeePtkp() {
            const id = this.$route.params.id
            this.$http
            .post("employee/ptkp/"+id+"/delete")
            .then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Employee PTKP has been deleted',
                        variant: 'danger',
                    },
                })
                location.href = "/settings/employee-ptkp"
                console.log(response.data.data)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            })
        },
    },
}
</script>